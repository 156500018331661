import {useState} from "react";
import {useTranslation} from "react-i18next";
import bigr from "./../assets/bigr_l.png";
import android from "./../assets/android.svg";
import apple from "./../assets/apple.svg";
import Popup from "../components/popup-download";
import {isMobile, isAndroid, isIOS} from "react-device-detect";
import {copyToClipboard} from "../lib/utils";
import {useSearchParams} from "react-router-dom";

const FixedBottomBar = () => {
  const {t} = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const adjustId = searchParams.get("adjustId") || "1gdir1x2";
  const campaign = searchParams.get("campaign");
  const adgroup = searchParams.get("adgroup");
  const creative = searchParams.get("creative");
  const fbclid = searchParams.get("fbclid");
  const fb_pixel_id = "1391015898524201";
  const fb_access_token =
    "EAANJHpHnkhABO0gdx8URjY2jRbJOsDZCYZCLvGE2tKbzgZBTB177R4pYeeKrmY7vN4GbFXEUKF0lxLb8CsSvlBG5IZC7nggd6SiZAJaXnfTtpiBQCZBMB0xB21ZAICfUXPBzTSQMhAeJZBZCmD1iLit4NQmGHQB7gosBjTPp12xGD9UtHZAFlMiAirLYGyfEZC7Cgj1UAZDZD";

  const onDownload = () => {
    const inviteCode = searchParams.get("invite");
    // Log or use these parameters as needed//
    console.log({adjustId, campaign, adgroup, creative, fbclid});
    if (inviteCode) {
      copyToClipboard(inviteCode as string);
      ////
    }
    if (!isMobile) {
      setShowPopup(true);
    } else {
      const downloadurl = "https://cfnnq.uoydymjg.com/api/c/ppb4ffzm";
      const encodedUrl = encodeURIComponent(downloadurl);
      if (fbclid) {
        const params = {
          campaign,
          adgroup,
          creative,
          fbclid,
          fb_pixel_id,
          fb_access_token
        };

        const queryString = Object.keys(params)
          .filter(
            (key) =>
              params[key as keyof typeof params] !== null &&
              params[key as keyof typeof params] !== undefined
          )
          .map(
            (key) =>
              `${key}=${encodeURIComponent(
                params[key as keyof typeof params] as string
              )}`
          )
          .join("&");

        window.open(
          `https://app.adjust.com/1gdir1x2?${queryString}&redirect=${encodedUrl}`,
          "_blank"
        );
        // window.open(`https://app.adjust.com/${adjustId}?campaign=${campaign}&adgroup=${adgroup}&creative=${creative}&fbclid=${fbclid}&redirect=${encodedUrl}&fb_pixel_id=${fb_pixel_id}&fb_access_token=${fb_access_token}`, '_blank')
      } else {
        window.open(downloadurl, "_blank");
      }
      // window.open("https://cfnnq.uoydymjg.com/api/c/ppb4ffzm", "_blank");
      // const link = document.createElement("a");
      // link.href = "https://qp-india.s3.ap-south-1.amazonaws.com/newinda.apk";
      // link.download = "BigRummy.apk";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="fixed bottom-0 w-full bg-[rgba(99,8,22,0.9)] text-white text-center py-0 md:py-0 z-20 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-90">
        <div className="flex flex-col md:flex-row items-center justify-center md:gap-4 gap-0 my-1">
          <div className="flex items-center justify-center gap-4 my-1">
            <img src={bigr} alt="Big Rummy" className="h-[30px] md:h-[50px]" />
            <span className="text-lg md:text-2xl font-bold font-custom">
              {t("downloadAppNow")}
            </span>
            <img src={android} alt="Big Rummy" className="h-[20px]" />
            <img src={apple} alt="Big Rummy" className="h-[20px]" />
          </div>
          <button
            className="relative inline-flex h-16 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-50 transform transition-all duration-300 hover:scale-110"
            onClick={onDownload}
          >
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#eccd89_0%,#856935_50%,#edc976_100%)]" />
            <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-70 px-8 font-bold text-gray-50 backdrop-blur-3xl text-3xl">
              {t("download")}
            </span>
          </button>
        </div>
      </div>
      {showPopup && <Popup onClose={handleClosePopup} />}
    </>
  );
};

export default FixedBottomBar;
